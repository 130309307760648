"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getApiDomain = getApiDomain;
function getApiDomain(messagesEnv, messagesHublet) {
  const envString = messagesEnv === 'qa' ? 'qa' : '';
  const hubletString = /* eslint-disable-next-line hubspot-dev/no-hublet-comparison, hubspot-dev/no-hublet-references */
  messagesHublet === 'na1' || !messagesHublet ? '' : `-${messagesHublet}`;
  return `https://api${hubletString}.hubspot${envString}.com`;
}